exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-index-jsx": () => import("./../../../src/pages/projects/index.jsx" /* webpackChunkName: "component---src-pages-projects-index-jsx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-jsx-content-file-path-opt-build-repo-projects-cookbook-app-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/projects/cookbook-app/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-jsx-content-file-path-opt-build-repo-projects-cookbook-app-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-jsx-content-file-path-opt-build-repo-projects-thera-notes-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/projects/thera-notes/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-jsx-content-file-path-opt-build-repo-projects-thera-notes-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-jsx-content-file-path-opt-build-repo-projects-virtual-mall-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.jsx?__contentFilePath=/opt/build/repo/projects/virtual-mall/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-jsx-content-file-path-opt-build-repo-projects-virtual-mall-index-mdx" */),
  "component---src-pages-techstack-jsx": () => import("./../../../src/pages/techstack.jsx" /* webpackChunkName: "component---src-pages-techstack-jsx" */)
}

